import React from 'react';
import { Row } from 'reactstrap';

// Social Icons
import facebookIcon from '@assets/social/facebook.svg';
import instagramIcon from '@assets/social/ig.svg';
import youtubeIcon from '@assets/social/youtube.svg';
import linkedInIcon from '@assets/social/linkedin.svg';

const Footer = () => {
  return (
    <>
      <div className="customer-care-info">
        <p className="mb-0">Customer Care 1-500-181</p>
        <a href="https://www.siloamhospitals.com" target="_blank" rel="noopener noreferrer">
          siloamhospitals.com
        </a>

        <Row className="justify-content-center">
          <a href="https://www.facebook.com/siloamhospitalsgroup" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/siloamhospitals/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="instagram" />
          </a>
          <a href="https://www.youtube.com/channel/UCgKxmZNgM7fi-E6Yidr-Q6g" target="_blank" rel="noopener noreferrer">
            <img src={youtubeIcon} alt="youtube" />
          </a>
          <a href="https://www.linkedin.com/company/siloam-hospitals-group" target="_blank" rel="noopener noreferrer">
            <img src={linkedInIcon} alt="linkedIn" />
          </a>
        </Row>
      </div>
      <div className="footer-info">
        <a href="/privacy-policy">Privacy & Policy</a>
        <p>© Copyright 2019, Siloam Hospitals Group</p>
      </div>
    </>
  );
};

export default Footer;
