import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '@components/Layout';

import HomeComponent from '@routes/Home';
import PrivacyPolicyComponent from '@routes/PrivacyPolicy';

const Routes = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact component={HomeComponent} />
        <Route path="/privacy-policy" exact component={PrivacyPolicyComponent} />
      </Switch>
    </Layout>
  );
};

export default Routes;
