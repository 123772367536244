import React, { useEffect, useState } from 'react';
import siloamLogo from '@assets/siloam-logo.png';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Container } from 'reactstrap';
import { Link } from 'react-scroll';

const DefaultHeader = () => {
  const [navOpacity, setNavOpacity] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(prevState => !prevState);

  useEffect(() => {
    const updateNavOpacity = () => {
      const endFade = 150;
      const startFade = endFade - 50;
      const scrollY = window.scrollY;

      if (scrollY < startFade) {
        if (navOpacity === 0) return;
        setNavOpacity(0);
        return;
      }

      if (scrollY > endFade) {
        if (navOpacity === 1) return;
        setNavOpacity(1);
        return;
      }

      const pxPastStartFade = scrollY - startFade;
      const newOpacity = pxPastStartFade / (endFade - startFade);

      setNavOpacity(newOpacity);
    };

    window.addEventListener('scroll', updateNavOpacity);

    return () => {
      window.removeEventListener('scroll', updateNavOpacity);
    };
  }, [navOpacity]);

  const opacity = navOpacity ? Math.max(navOpacity, 0.2) : 0;

  const headerStyle = {
    backgroundColor: `rgba(255, 255, 255, ${opacity})`,
    boxShadow: `rgba(0, 0, 0, ${opacity - 0.75}) 0px 0px 10px 0px`,
  };

  const navLinksStyle = {
    color: opacity > 0.5 ? '#222D7F' : 'white',
  };

  const isHomePage = () => {
    return window.location.pathname === '/';
  };

  return (
    <>
      <Navbar light className="header-wrapper" style={headerStyle} expand="md">
        <Container>
          <NavbarBrand href={isHomePage() && 'https://siloamhospitals.com'}>
            <img src={siloamLogo} alt="MySiloam Logo" />
          </NavbarBrand>
          {isHomePage() && <NavbarToggler onClick={toggle} />}
          {isHomePage() && (
            <Collapse isOpen={false} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link smooth to="hospital-info" className="nav-links ml-0" style={navLinksStyle}>
                    Hospital Information
                  </Link>
                </NavItem>
                <NavItem>
                  <Link smooth to="book-your-doctor" className="nav-links" style={navLinksStyle}>
                    Book Your Doctor
                  </Link>
                </NavItem>
                <NavItem>
                  <Link smooth to="diabisa" className="nav-links" style={navLinksStyle}>
                    DiaBISA
                  </Link>
                </NavItem>
                <NavItem>
                  <Link smooth to="hospital-list" className="nav-links mr-0" style={navLinksStyle}>
                    Hospital List
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          )}
        </Container>
      </Navbar>
      <div className={`mysiloam-sidebar${isOpen ? ' open' : ''}`}>
        <div className="sidebar-header">
          <button onClick={toggle} type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="sidebar-content">
          <ul>
            <li>
              <Link onClick={toggle} smooth to="hospital-info">
                Hospital Information
              </Link>
            </li>
            <li>
              <Link onClick={toggle} smooth to="book-your-doctor">
                Book Your Doctor
              </Link>
            </li>
            <li>
              <Link onClick={toggle} smooth to="diabisa">
                DiaBISA
              </Link>
            </li>
            <li>
              <Link onClick={toggle} smooth to="hospital-list">
                Hospital List
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div role="presentation" onClick={toggle} className={`mysiloam-sidebar-backdrop${isOpen ? ' open' : ''}`} />
    </>
  );
};

export default DefaultHeader;
