import loadable from '@loadable/component';

const errorLoading = err => console.log('Privacy policy page loading failed!', err);

const PrivacyPolicyComponent = loadable(
  () => import(/* webpackChunkName: "privacy-policy" */ '@routes/PrivacyPolicy/components').catch(errorLoading),
  {
    ssr: true,
  },
);

export default PrivacyPolicyComponent;
